<!--
 * @Author: your name
 * @Date: 2021-01-18 18:55:20
 * @LastEditTime: 2021-01-18 19:16:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\product\index.vue
-->
<template>
  <div>
    <navigation :msg="msg"></navigation>
    <div class="chanpinclasses">
      <div @click="$router.push('/postone')" class="chanpinclass">
        <div class="chanimg"><img src="../../assets/chan1.png" /></div>
        <div class="chanrightclass">
          <div>初级岗位包</div>
          <div class="now">适用于保安/保洁/网约车司机/家政月嫂</div>
          <div>
            <div></div>
            <!-- <van-icon
              class="theicclass"
              color="#979797"
              size=".3rem"
              name="arrow"
            /> -->
          </div>
          <div>月薪5000以下公司/工厂的基础岗位</div>
          <div>19.9</div>
        </div>
      </div>
    </div>

    <div class="chanpinclasses">
      <div @click="$router.push('/posttwo')" class="chanpinclass">
        <div class="chanimg"><img src="../../assets/chan2.png" /></div>
        <div class="chanrightclass">
          <div>中级岗位包</div>
          <div>适用于人事行政类/销售类等</div>
          <div>
            <div></div>
            <!-- <van-icon class="theicclass" size=".3rem" name="arrow" /> -->
          </div>
          <div>公司中层管理岗位</div>
          <div>49.9</div>
        </div>
      </div>
    </div>

    <div class="chanpinclasses">
      <div @click="$router.push('/postthree')" class="chanpinclass">
        <div class="chanimg"><img src="../../assets/chan3.png" /></div>
        <div class="chanrightclass">
          <div>高级岗位包</div>
          <div>适用于金融类/财务类/高级技术类等</div>
          <div>
            <div></div>
            <!-- <van-icon class="theicclass" size=".3rem" name="arrow" /> -->
          </div>
          <div>公司高层管理岗位</div>
          <div>99.9</div>
        </div>
      </div>
    </div>

    <div class="chanpinclasses">
      <div @click="$router.push('/postfour')" class="chanpinclass" id="nobot">
        <div class="chanimg"><img src="../../assets/chan4.png" /></div>
        <div class="chanrightclass">
          <div>自定义产品</div>
          <div>适用自定义产品的选择</div>
          <div>
            <div></div>
            <!-- <van-icon class="theicclass" size=".3rem" name="arrow" /> -->
          </div>
          <div>自定义需求</div>
          <div>按照所选择产品总计</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    
  },
  data() {
    return {
      msg: "产品中心",
    };
  },
  methods:{
      
  }
};
</script>
<style scoped>
.chanpinclasses {
  background-color: #ffffff;
  margin: 0.24rem;
  padding: 0.24rem;
  border-radius: 0.1rem;
}
.theicclass {
  transform: translateY(0.05rem);
}
.botmsgclass {
  margin: 0.32rem 0.22rem;
  background-image: url("../../assets/beiimg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-color: #ffffff;
  padding: 0.34rem 0.23rem;
}
.myc span {
  color: #f55814;
}
.myc {
  font-size: 0.4rem;
  line-height: 0.56rem;
  font-weight: bold;
}
.chanpinclass {
  display: flex;
  border-radius: 0.2rem;
  overflow: hidden;
}
.chanimg {
  width: 2rem;
  min-width: 2rem;
  height: 2.08rem;
  margin-right: 0.29rem;
}
.chanimg img {
  width: 100%;
  height: 100%;
}
.chanrightclass > div:nth-child(5) {
  font-size: 0.32rem;
  line-height: 0.45rem;
  color: #f55814;
  font-weight: bold;
}
.chanrightclass > div:nth-child(4) {
  font-size: 0.18rem;
  line-height: 0.25rem;
  color: #f55814;
  margin-bottom: 0.19rem;
  margin-top: .19rem;
  background-color: #FFF2E0;
  display: inline-block;
  padding: .06rem .1rem;
}
.chanrightclass > div:nth-child(3) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chanrightclass > div:nth-child(2) {
  color: #b8b8b8;
  font-size: 0.24rem;
  line-height: 0.33rem;
  margin: 0.08rem 0;
}
.chanrightclass > div:first-child {
  font-size: 0.34rem;
  line-height: 0.48rem;
  color: #222222;
  font-weight: bold;
}
.chanrightclass {
  width: 100%;
}
.now {
  white-space: nowrap;
}
</style>
